import {ChangeDetectorRef, Component, OnInit, Optional, TemplateRef} from '@angular/core';
import {UploadService} from '../../services/upload.service';
import {ActivatedRoute, Router} from '@angular/router';
import {NbThemeService, NbToastrService} from '@nebular/theme';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../../../user/models/User';
import {NbAuthJWTToken, NbAuthService} from '@nebular/auth';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NbDialogService, NbDialogRef} from '@nebular/theme';

import {Subject, Observable} from 'rxjs';
import {WebcamImage, WebcamInitError, WebcamUtil} from 'ngx-webcam';
import {WalletsService} from '../../../user/services/wallets.service';
import {EncryptedWallet} from '../../../auth/models/EncryptedWallet';
import CryptoAES from 'crypto-js/aes';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'dedit-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class DeditHomeComponent implements OnInit {
  tabletOrDesktop: boolean;
  desktop: boolean;
  tablet: boolean;
  mobile: boolean;
  hashing = false;
  wallets: EncryptedWallet[];
  f: File;

  mode = 'register';

  // tslint:disable-next-line:max-line-length
  constructor(private router: Router,
              private toastrService: NbToastrService,
              private route: ActivatedRoute,
              private themeService: NbThemeService,
              private uploadService: UploadService,
              public translate: TranslateService,
              private _snackBar: MatSnackBar,
              private authService: NbAuthService,
              private dialogService: NbDialogService,
              private walletsService: WalletsService,
              private ref: ChangeDetectorRef,
              @Optional() private dialogRef: NbDialogRef<any>
  ) {
    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable
        } else {
          this.user = undefined;
          // if private instance redirect to login
          if (environment["private-instance"]) {
            this.router.navigate(['/auth/login']).then(r => {})
          }
        }
      });

    const _mode: string = this.route.snapshot.queryParams.mode;
    if (_mode === 'verify') {
      this.mode = 'verify';
    } else {
      this.mode = 'register';
    }
  }

  user: User;
  pastedHash: any;

  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];

  // latest snapshot
  public webcamImage: WebcamImage = null;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();
  buttonMargin: any;
  mobileWidth : any;
  webcamHeight: any;
  webcamWidth: any;
  pratSel: string;
  pratSelDescr: string;
  roleID: string;
  ngOnInit() {
    const psw = localStorage.getItem('password')|| 'defaultPassword';
    this.walletsService.wallets().toPromise()
      .then(wallets => {
        this.wallets = wallets;
        this.ref.detectChanges();
      })
      .then(wallets => {
        if (this.checkIfNeedAlgorandWallet(this.wallets)) {
          const algosdk = require('algosdk');
          const account = algosdk.generateAccount();
          const secretkey = account.sk
          const encryptedSecretkey = CryptoAES.encrypt(
            secretkey.toString(),
            psw
          );
          const algowallet ='{\'secret_key\': \''+encryptedSecretkey+'\', \'address\': \''+account.addr+'\', \'algorithm\': \'aes\'}';
          this.walletsService.createAlgorandWallet(algowallet).toPromise()
            .then(wallet => {
            })
        }
      })
      .catch(err => {

        const algosdk = require('algosdk');
        const account = algosdk.generateAccount();
        const secretkey = account.sk
        const encryptedSecretkey = CryptoAES.encrypt(
          secretkey.toString(),
          psw
        );
        const algowallet ='{\'secret_key\': \''+encryptedSecretkey+'\', \'address\': \''+account.addr+'\', \'algorithm\': \'aes\'}';
        this.walletsService.createAlgorandWallet(algowallet).toPromise()
        .then(wallet => {
        })
      });
    this.themeService.onMediaQueryChange().subscribe(breakpoints => {
      this.tabletOrDesktop = breakpoints[1].width > 991;
      this.desktop = breakpoints[1].width > 91199;
      this.tablet = breakpoints[1].width > 991;
      this.mobile = breakpoints[1].width < 401;
    });
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
    if (this.mobile) {
      this.buttonMargin = '10px 0px';
      this.webcamWidth = 300;
      this.webcamHeight = 300;
    } else {
      this.buttonMargin= '0px 10px';
      this.mobileWidth = '30%';
      this.webcamWidth = 500;
      this.webcamHeight = 500;
    }
    this.pratSel=localStorage.getItem('pratSel');
    this.pratSelDescr=localStorage.getItem('pratSelDescr');
    
    this.roleID=localStorage.getItem('roleid');
    
  }

  checkIfNeedAlgorandWallet(wallets: EncryptedWallet[]) {
    let haveAlgorandWallet = false;
    let haveEthereumWallet = false;
    for (const val of wallets) {
      if (val.active===true) {
        if (val.walletType==='ethereum') haveEthereumWallet=true;
        else if (val.walletType==='algorand') haveAlgorandWallet=true;
      }
    }
    return !haveAlgorandWallet && haveEthereumWallet;
  }

  upload(files: FileList) {
    this.f = files.item(0);
    if (typeof Worker !== 'undefined') {
      this.hashing = true;
      this.uploadService.sha256Worker(this.f).then(
        hash => {
          this.hashing = false;
          this.uploadService.file().next(this.f); // = routing with a payload
          this.router.navigate(['/verification/' + hash]);
        }
      );
    } else {
      this.hashing = true;
      // Web Workers are not supported in this environment.
      this.uploadService.sha256(this.f).then(
        hash => {
          this.hashing = false;
          this.uploadService.file().next(this.f); // = routing with a payload
          this.router.navigate(['/verification/' + hash]);
        }
      );
    }
  }

  uploadFile(file: File) {
    this.f = file;
    if (typeof Worker !== 'undefined') {
      this.hashing = true;
      this.uploadService.sha256Worker(this.f).then(
        hash => {
          this.hashing = false;
          this.uploadService.file().next(this.f); // = routing with a payload
          this.dialogRef.close();
          this.router.navigate(['/verification/' + hash]);
        }
      );
    } else {
      this.hashing = true;
      // Web Workers are not supported in this environment.
      this.uploadService.sha256(this.f).then(
        hash => {
          this.hashing = false;
          this.uploadService.file().next(this.f); // = routing with a payload
          this.dialogRef.close();
          this.router.navigate(['/verification/' + hash]);
        }
      );
    }
  }

  pastaVerification() {
    if (!this.pastedHash) {
      return;
    }
    if (this.pastedHash.length !== 64) {
      this.showToast('warning', 'Hash non supportato', 'È possibile verificare solo hash SHA-256', 15000);
      return;
    }
    this.router.navigate(['/verification/' + this.pastedHash]);
  }

  switchMode(mode: string) {
    mode === 'verify' ? this.mode = 'verify' : this.mode = 'register';
  }

  openWindow(dialog: TemplateRef<any>) {
    this.dialogRef = this.dialogService.open(dialog);
  }

  private showToast(status, title, message, duration?) {
    /*    this.toastrService.show(message, title,
          duration ? {
            status,
            duration,
            position: NbGlobalPhysicalPosition.BOTTOM_RIGHT
          } : {
            status,
            duration: 0,
            destroyByClick: true,
            position: NbGlobalPhysicalPosition.BOTTOM_RIGHT
          });*/

    this._snackBar.open(title + '. ' + message, status.toUpperCase(), {
      duration: duration ? duration : 5000, panelClass: ['blue-snackbar']
    });
  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public handleImage(webcamImage: WebcamImage): void {
    console.log('received webcam image', webcamImage);
    this.webcamImage = webcamImage;
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  registerPic() {
    const base64 = this.webcamImage.imageAsBase64;
    const imageName = 'webcamImage.png';
    const imageBlob = this.dataURItoBlob(base64);
    const imageFile = new File([imageBlob], imageName, { type: 'image/png' });
    this.uploadFile(imageFile);
  }

  undoPic() {
    this.webcamImage = null;
  }
}
