import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {Registration} from '../../core/models/Registration';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {NbAuthService} from '@nebular/auth';

@Injectable({
  providedIn: 'root'
})
export class RegistrationsService {
  private registrationApi = environment.api + '/registration/';

  // tslint:disable-next-line:max-line-length
  constructor(protected http: HttpClient, private authService: NbAuthService) {}

  registrations(): Observable<Registration[]> {
    return this.authService.getToken().pipe(switchMap(
      token => this.http.get<Registration[]>(
        this.registrationApi,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer '.concat(token.getValue())
          })
        })
    ));
  }

  registrationsAwaitingSignature(): Observable<Registration[]> {
    return this.authService.getToken().pipe(switchMap(
      token => this.http.get<Registration[]>(
        this.registrationApi + 'awaiting-signature',
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer '.concat(token.getValue())
          })
        })
    ));
  }
}

