import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NbAuthService} from '@nebular/auth';
import {Observable} from 'rxjs';
import {EncryptedWallet} from '../../auth/models/EncryptedWallet';
import {switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WalletsService {
  private walletApi = environment.api + '/wallet/';

  constructor(private http: HttpClient, private authService: NbAuthService) { }

  wallets(): Observable<EncryptedWallet[]> {
    return this.authService.getToken().pipe(switchMap(
      token => this.http.get<EncryptedWallet[]>(
        this.walletApi,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer '.concat(token.getValue())
          })
        })
    ));
  }

  createAlgorandWallet(algowallet: string): Observable<any> {
    return this.authService.getToken().pipe(switchMap(
      token =>  this.http.post<any>(
        this.walletApi + 'createalgorandwallet',
        algowallet,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer '.concat(token.getValue())
          })
        })
    ));
  }
  newActiveAlgorandWallet(algowallet: string, jwt): Observable<any> {
    return this.authService.getToken().pipe(switchMap(
      token =>  this.http.post<any>(
        this.walletApi + 'createalgorandwallet',
        algowallet,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer '.concat(jwt)
          })
        })
    ));
  }
}
