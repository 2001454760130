import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {RegistrationService} from '../../services/registration.service';
import {Registration} from '../../models/Registration';
import {IPayPalConfig} from 'ngx-paypal';
import {WalletService} from '../../../auth/services/wallet.service';
import {ethers} from 'ethers';
import {Router} from '@angular/router';
import {NbAuthJWTToken, NbAuthService} from '@nebular/auth';
import {NbGlobalPhysicalPosition, NbThemeService, NbToastrService} from '@nebular/theme';
import {PaymentsService} from '../../../user/services/payments.service';
import {Subscription} from '../../../user/models/Subscription';
import {FileService} from '../../services/file.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {User} from '../../../user/models/User';
import {UserService} from '../../../user/services/user.service';
import CryptoAES from 'crypto-js/aes';
import crypto from 'crypto-js';

@Component({
  selector: 'dedit-practice',
  templateUrl: './practice.component.html',
  styleUrls: ['./practice.component.scss']
})
export class DeditPracticeComponent implements OnInit {
  registration: Registration;
  file: File;
  price = '--.-';
  payPalConfig?: IPayPalConfig;
  desktop: boolean;
  tablet: boolean;
  mobile: boolean;

  user;
  thisUser: User;
  registering: boolean;
  progress = {value: 0, wallet: false, custody: false, signed: false, broadcast: false};
  errors;
  count: number;
  activeSub: Subscription;

  fileCount = 0;
  walletType = 'algorand';
  psw = localStorage.getItem('password') || 'defaultPassword';
  password = localStorage.getItem('password') || 'defaultPassword';
  canCustody = true;
  planCount: any;
  isCliente=false;
  pratSel:string;

  constructor(private router: Router, private themeService: NbThemeService,
              private registrationService: RegistrationService,
              private custodyService: FileService,
              private _snackBar: MatSnackBar,
              private toastrService: NbToastrService,
              private userService: UserService,
              private ref: ChangeDetectorRef,
              private walletService: WalletService,
              private authService: NbAuthService,
              private paymentsService: PaymentsService) {
    this.themeService.onMediaQueryChange().subscribe(breakpoints => {
      this.desktop = breakpoints[1].width > 1199;
      this.tablet = breakpoints[1].width > 991;
      this.mobile = breakpoints[1].width < 401;
    });
    this.registration = this.registrationService.registration().getValue();
    // if (!this.registration) { throw Error('Cannot register nothing!'); }
    this.registration.custody = false;
    this.registration.signed = false;
    // this.walletType = 'ethereum';
    this.registration.blockchainType = 'ALGORAND';

    this.file = this.registration.file;
    delete this.registration.file;

    this.registering = false;
    this.progress.value = 0;
    this.errors = [];
  }

  ngOnInit() {
    this.userService.user().toPromise().then(u => {
      this.user = u;
      localStorage.setItem('roleid',u.roleid);
      localStorage.setItem('groupid',u.groupid);
      this.ref.detectChanges();
    });
    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable
        } else {
          this.user = undefined;
        }
      });

    this.paymentsService.activeSubscription().toPromise().then(aS => {
      console.log('Active sub:', aS)
      this.activeSub = aS;
    })
      .catch(err => {
        console.log('No active sub:', err);
        this.registrationService.stripeDisabled().toPromise()
          .then(res => console.log("STRIPE_DISABLED", res))
      });

    this.registrationService.registrationCount().toPromise()
      .then(res => this.count = res.registrationsLimit-res.registrationsUsed)

    this.registrationService.registrationPrice().toPromise().then(p => {
      this.price = p.entity;
      setTimeout(() => {
        if (this.user) {
          this.authService.getToken().toPromise().then(jwt =>
            this.custodyService.countMetadata(jwt.getValue()).toPromise().then(
              res => {
                this.fileCount = res.custodyUsed;
                this.planCount = res.custodyLimit;
                this.canCustody = res.custodyUsed <= res.custodyLimit*1000000;
                this.ref.detectChanges();
              }
            ).catch(res => console.log('No file metadata in custody'))
          );
        }
      }, 1000);
    });
    this.isCliente=(localStorage.getItem('logex')=='?');
    this.pratSel=localStorage.getItem('pratSel');
    
  }

  private showToast(status, title, message, duration?) {
      /* this.toastrService.show(message, title,
          duration ? {
            status,
            duration,
            position: NbGlobalPhysicalPosition.BOTTOM_RIGHT
          } : {
            status,
            duration: 0,
            destroyByClick: true,
            position: NbGlobalPhysicalPosition.BOTTOM_RIGHT
          });*/

    this._snackBar.open(title + '. ' + message, status.toUpperCase(), {
      duration: duration ? duration : 5000,
      panelClass: 'snackbar'
    });
  }

  async register(approveData) {
    this.registering = true;

    const password = this.password;
    this.progress.value = 5;

    if (this.registration.signed || this.registration.custody) {
      if (this.registration.blockchainType === 'ALGORAND' || this.registration.custody) {
        this.progress.custody = true;
        await this.registrationService.custody(this.file)
          .catch(e => {
            this.errors.push(e);
            this.progress.value = 5;
            this.registering = false;
          });
        this.progress.value = 50;
        this.progress.custody = false;
      }
    }

    this.progress.signed = true;
    let sigSig;

    // start wallet
    this.progress.wallet = true;
    const encryptedWallet = await this.walletService.activeWallet().toPromise();
    if (this.registration.blockchainType === 'ETHEREUM') {
      const wallet = await ethers.Wallet.fromEncryptedJson(encryptedWallet.json, password);
      this.progress.wallet = false;
      // end wallet

      sigSig = await this.registrationService.signRegistration(this.registration, wallet).catch(e => {
        this.errors.push(e);
        this.progress.value = 5;
        this.registering = false;
      });

    } else {
      this.progress.wallet = false;
      // end wallet
    }
    this.registration.signer = sigSig[0];
    this.registration.signature = sigSig[1];
    this.progress.value = 75;
    this.progress.signed = false;

    this.progress.broadcast = true;

    return await this.registrationService.completeRegistration(this.registration, {order_id: approveData.orderID})
      .then(
        completeOrder => {
          this.progress.value = 100;
          // this.progress.broadcast = false;
          // this.registering = false;
          setTimeout(() => this.router.navigate(['/verification/' + this.registration.hash]), 1500);
        },
        err => {
          this.errors.push(err);
          this.progress.value = 5;
          this.registering = false;
        }
      );
  }

  async registerForfait() {
    this.registering = true;
    if (this.user.defaultWallet === 'user_algorand') this.registration.signed = true;

    await this.registrationService.requestRegistration(this.registration, true);

    if (this.registration.custody) {
      this.progress.custody = true;
      await this.registrationService.custody(this.file).catch(e => {
        this.errors.push(e);
        this.progress.value = 5;
        this.registering = false;
      });

      this.progress.custody = false;
    }

    this.progress.value = 50;

    this.progress.signed = true;

    // ZZZ: ethereum not active
    if (this.walletType === 'ethereum') {
      this.progress.wallet = true;
      const encryptedWallet = await this.walletService.activeWallet().toPromise();
      const wallet = await ethers.Wallet.fromEncryptedJson(encryptedWallet.json, this.password);
      this.progress.wallet = false;
      const sigSig = await this.registrationService.signRegistration(this.registration, wallet).catch(e => {
        this.errors.push(e);
        this.progress.value = 5;
        this.registering = false;
      });
      this.registration.signer = sigSig[0];
      this.registration.signature = sigSig[1];

    } else if (this.walletType === 'algorand' && this.user.defaultWallet === 'user_algorand') {
      const algorandWallet = await this.walletService.getWallet('algorand').toPromise();
      this.registration.signer = algorandWallet.name;
      const parsedAlgowallet = JSON.parse(algorandWallet.json);
      const encryptedPrivateKey = parsedAlgowallet.secret_key;
      const privateKey = CryptoAES.decrypt(encryptedPrivateKey.toString(), this.password).toString(crypto.enc.Utf8);
      const address = parsedAlgowallet.address;
      const [, signedPayload] = await this.registrationService.signRegistration(this.registration, address, privateKey);
      this.registration.signature = signedPayload as string;
    }

    this.progress.signed = false;
    this.progress.value = 75;
    this.progress.broadcast = true;
    return await this.registrationService.forfaitRegistration(this.registration, this.activeSub).then(
      reg => {
        this.progress.value = 100;
        // this.progress.broadcast = false;
        // this.registering = false;
        setTimeout(() => this.router.navigate(['/verification/' + this.registration.hash]), 1500);
      },
      err => {
        this.errors.push(err);
        this.progress.value = 5;
        this.registering = false;
      }
    );
  }

  async registerFree() {
    this.registering = true;
    if (this.registration.signed || this.registration.custody) {
      const password = this.password;

      const subscription = await this.registrationService.requestRegistration(this.registration, true);

      this.progress.value = 5;
      this.progress.wallet = true;
      const encryptedWallet = await this.walletService.activeWallet().toPromise();
      this.progress.value = 15;
      const wallet = await ethers.Wallet.fromEncryptedJson(encryptedWallet.json, password);
      this.progress.value = 25;
      this.progress.wallet = false;

      if (this.registration.custody) {
        this.progress.custody = true;
        await this.registrationService.custody(this.file).catch(e => {
          this.errors.push(e);
          this.progress.value = 5;
          this.registering = false;
        });
        this.progress.value = 50;
        this.progress.custody = false;
      }

      if (this.registration.signed) {
        this.progress.signed = true;
        const sigSig = await this.registrationService.signRegistration(this.registration, wallet).catch(e => {
          this.errors.push(e);
          this.progress.value = 5;
          this.registering = false;
        });
        this.registration.signer = sigSig[0];
        this.registration.signature = sigSig[1];
        this.progress.value = 75;
        this.progress.signed = false;
      }
    }

    this.progress.broadcast = true;
    return await this.registrationService.freeRegistration(this.registration).then(
      reg => {
        this.progress.value = 100;
        // this.progress.broadcast = false;
        // this.registering = false;
        setTimeout(() => this.router.navigate(['/verification/' + this.registration.hash]), 1500);
      },
      err => {
        this.errors.push(err);
        this.progress.value = 5;
        this.registering = false;
      }
    );
  }

  prettifyHash(hash: string): string[] {
    hash = hash.replace('0x', '');
    const hash1 = hash.slice(0, hash.length / 2);
    const hash2 = hash.slice(hash.length / 2, hash.length);
    const hash11 = hash1.slice(0, hash1.length / 2).toUpperCase();
    const hash12 = hash1.slice(hash1.length / 2, hash1.length).toUpperCase();
    const hash21 = hash2.slice(0, hash2.length / 2).toUpperCase();
    const hash22 = hash2.slice(hash2.length / 2, hash2.length).toUpperCase();
    return [hash11 + '' + hash12, hash21 + '' + hash22];
  }

  private initPayPal(): void {
    this.payPalConfig = {
      currency: 'EUR',
      style: {
        label: 'checkout',
        layout: 'vertical',
        size: 'responsive',
        shape: 'pill',
        color: 'blue'
      },
      clientId: environment.paypal,
      // for creating orders (transactions) on server see
      // https://developer.paypal.com/docs/checkout/reference/server-integration/set-up-transaction/
      createOrderOnServer: async data => {
        this.registering = true;
        return await this.registrationService.requestRegistration(this.registration);
      },
      onApprove: async () => {
        this.registering = true;
      },
      authorizeOnServer: async (approveData) => await this.register(approveData),
      onCancel: (data, actions) => {
        // console.log('OnCancel', data, actions);
      },
      onError: err => {
        // console.log('OnError', err); lots of bloating... es. 'Window navigated away'
      },
      onClick: () => {
        // console.log('onClick');
      }
    };
  }

  up() {
    window.scroll(0, 0);
  }
}
