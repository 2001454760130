import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'dedit-tos',
  templateUrl: './tos.component.html',
  styleUrls: ['./tos.component.scss']
})
export class DeditToSComponent implements OnInit {

  language = 'en';

  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.language = this.translate.getBrowserLang();
  }

}
