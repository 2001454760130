import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {NbAuthService} from '@nebular/auth';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {Subscription} from '../models/Subscription';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  private paymentApi = environment.api + '/payment/';

  constructor(protected http: HttpClient, private authService: NbAuthService) {}

  createSubscription(plan: string): Observable<Subscription> {
    return this.authService.getToken().pipe(switchMap(
      token => this.http.post<Subscription>(
        this.paymentApi + 'subscription' + '/' + plan,
        null,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer '.concat(token.getValue())
          })
        })
    ));
  }

  updateSubscription(session: string): Observable<Subscription> {
    return this.authService.getToken().pipe(switchMap(
      token => this.http.put<Subscription>(
        this.paymentApi + 'subscription' + '/' + session,
        null,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer '.concat(token.getValue())
          })
        })
    ));
  }

  cancelSubscription(session: string): Observable<Subscription> {
    return this.authService.getToken().pipe(switchMap(
      token => this.http.delete<Subscription>(
        this.paymentApi + 'subscription' + '/' + session,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer '.concat(token.getValue())
          })
        })
    ));
  }

  activeSubscription(): Observable<Subscription> {
    return this.authService.getToken().pipe(switchMap(
      token => this.http.get<Subscription>(
        this.paymentApi + 'subscription' + '/active',
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer '.concat(token.getValue())
          })
        })
    ));
  }

  authenticSubscription(sessionId: string): Observable<any> {
    return this.authService.getToken().pipe(switchMap(
      token => this.http.get<any>(
        this.paymentApi + 'subscription' + '/' + sessionId + '?authentic=' + true,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer '.concat(token.getValue())
          })
        })
    ));
  }

  subscriptions(): Observable<Subscription[]> {
    return this.authService.getToken().pipe(switchMap(
      token => this.http.get<Subscription[]>(
        this.paymentApi + 'subscription' + '/all',
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer '.concat(token.getValue())
          })
        })
    ));
  }

  authenticPayment(orderId: string): Observable<any> {
    return this.authService.getToken().pipe(switchMap(
      token => this.http.get<any>(
        this.paymentApi + orderId + '?authentic=true',
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer '.concat(token.getValue())
          })
        })
    ));
  }

  payments(): Observable<any[]> {
    return this.authService.getToken().pipe(switchMap(
      token => this.http.get<any[]>(
        this.paymentApi,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer '.concat(token.getValue())
          })
        })
    ));
  }


  invoices(): Observable<any[]> {
    return this.authService.getToken().pipe(switchMap(
      token => this.http.get<any[]>(
        this.paymentApi+'/invoices/all',
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer '.concat(token.getValue())
          })
        })
    ));
  }
}
