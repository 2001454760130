import { Component, OnInit } from '@angular/core';
import { NbMenuService, NbPosition, NbThemeService, NbToastrService } from '@nebular/theme';
import { filter, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NbAuthService } from '@nebular/auth';
import { UserService } from 'src/app/user/services/user.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'dedit-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class DeditSupportComponent implements OnInit {
  private userApi = environment.api + '/user/';

  tabletOrDesktop: boolean;
  desktop: boolean;
  tablet: boolean;
  mobile: boolean;
  vw1500px: boolean;
  human: boolean;
  today = Date.now();
  top: NbPosition = NbPosition.TOP;
  bottom: NbPosition = NbPosition.BOTTOM;
  message = {
    name: '',
    email: '',
    body: ''
  };
  // tslint:disable-next-line:max-line-length
  constructor(
    private http: HttpClient,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute, protected router: Router, private themeService: NbThemeService, private authService: NbAuthService, private userService: UserService,
    private nbMenuService: NbMenuService, private toastrService: NbToastrService) {

  }

  ngOnInit() {
    this.authService.isAuthenticated().toPromise().then((v) => {
      if (v) {
        this.userService.user().toPromise().then((u) => {
          this.message.email = u.email;
          this.message.name = `${u.firstName} ${u.lastName}`;
        });
      }
    });

    this.themeService.onMediaQueryChange().subscribe(breakpoints => {
      this.tabletOrDesktop = breakpoints[1].width > 991;
      this.desktop = breakpoints[1].width > 91199;
      this.tablet = breakpoints[1].width > 991;
      this.mobile = breakpoints[1].width < 401;
      this.vw1500px = breakpoints[1].width > 1500;
    });

    const topic: string = this.route.snapshot.queryParams.topic;
    if (topic === 'dedit-e') {
      this.message.body = 'DEDIT ENTERPRISE: ';
    }

    this.nbMenuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'support'),
        map(({ item: { title } }) => title),
      )
      .subscribe(title => {
        switch (title) {
          case 'Email':
            const m = window.open('mailto:info@blockchainitalia.io', 'emailWindow');
            if (m && m.open && !m.closed) { m.close(); }
            break;
          case 'Phone':
            const t = window.open('tel:+390287176504');
            if (t && t.open && !t.closed) { t.close(); }
        }
      });
  }

  recaptcha(e): void {
    this.human = !!e;
  }

  sendMessage() {
    const { body, name, email } = this.message;
    const subject = "Support ticket from " + email + " " + name;
    const emailPromise = this.http.post<any>(this.userApi + 'send-email-support' + '?to=' + environment.supportTo + '&cc=' + email + '&bcc=' + environment.supportBcc + '&subject=' + subject + '&body=' + body, {}).toPromise();
    emailPromise.then(
      (data) => {
        // tslint:disable-next-line:max-line-length
        this.showToast('success', 'Messaggio inviato correttamente', 'Una copia è stata inviata alla tua mail. Il team di Dedit risponderà al vostro messaggio nei tempi più brevi', 15000);
      }).catch(
        (err: Error) => {
          console.error(err, err.stack);
          console.log(err.message);
          if (err.name.includes('InvalidParameterValue')) {
            this.showToast('warning', 'Check email field', '', 10000);
            return;
          }
          this.showToast('warning', 'Error while sending ticket', '', 10000);
          return;
        });

  }

  private showToast(status, title, message, duration?) {
    this._snackBar.open(title + '. ' + message, status.toUpperCase(), {
      duration: duration ? duration : 5000, panelClass: ['blue-snackbar']
    });
  }
}
