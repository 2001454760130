import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {NbThemeService} from '@nebular/theme';

@Component({
  selector: 'dedit-business',
  templateUrl: './business.component.html',
  styleUrls: ['./business.component.scss']
})
export class DeditBusinessComponent implements OnInit {
  tabletOrDesktop: boolean;
  desktop: boolean;
  tablet: boolean;
  mobile: boolean;
  vw1500px: boolean;
  industries = ['1', '2', '3', '4', '5'];
  constructor(private router: Router, private themeService: NbThemeService) { }

  ngOnInit() {
    this.themeService.onMediaQueryChange().subscribe(breakpoints => {
      this.tabletOrDesktop = breakpoints[1].width > 991;
      this.desktop = breakpoints[1].width > 91199;
      this.tablet = breakpoints[1].width > 991;
      this.mobile = breakpoints[1].width < 401;
      this.vw1500px = breakpoints[1].width > 1500;
    });
  }
}
