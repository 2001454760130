import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {Registration} from '../models/Registration';
import {Router} from '@angular/router';
import {NbAuthService} from '@nebular/auth';
import {ethers, Wallet} from 'ethers';
import {TransactionService} from './transaction.service';
import {FileService} from './file.service';
import {Metadata} from '../models/Metadata';
import {switchMap} from 'rxjs/operators';
import {Subscription} from '../../user/models/Subscription';
import {sha256} from 'js-sha256';
import * as algosdk from 'algosdk';
import * as ed from 'noble-ed25519';

@Injectable({
  providedIn: 'root'
})

export class RegistrationService {
  private registrationApi = environment.api + '/registration/';
  private readonly registrationBehaviorSubject: BehaviorSubject<Registration>;

  // tslint:disable-next-line:max-line-length
  constructor(protected http: HttpClient, protected router: Router, private authService: NbAuthService, private transactionService: TransactionService, private fileService: FileService) {
    this.registrationBehaviorSubject = new BehaviorSubject<Registration>(null);
  }

  registration() {
    return this.registrationBehaviorSubject;
  }

  registrationPrice() {
    return this.http.get<any>(this.registrationApi + 'price');
  }

  registrationCount() {
    return this.authService.getToken().pipe(switchMap(
      token => this.http.patch<any>(
        this.registrationApi + 'count', null,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer '.concat(token.getValue())
          })
        })
    ));
  }

  stripeDisabled() {
    return this.http.get<any>(
      this.registrationApi + 'stripe-disabled',
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          // Authorization: 'Bearer '.concat(token.getValue())
        })
      })
  }

  async requestRegistration(registration: Registration, subscription = false): Promise<string> {
    const token = (await this.authService.getToken().toPromise());
    if (token && token.isValid()) {
     // alert('zz');
      return this.http.post<any>(this.registrationApi + '?subscription=' + subscription, registration,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer '.concat(token.getValue())
          })
        }).toPromise().then(
        order => order.order_id,
        err => err.message
      );
    } else {
      return this.http.post<any>(this.registrationApi, registration).toPromise().then(
        order => order.order_id,
        err => err.message
      );
    }
  }

  async completeRegistration(registration: Registration, order) {
    let params = registration.hash + '/completion';
    if (registration.signer && registration.signature) {
      params = params + '?signer=' + registration.signer + '&signature=' + registration.signature;
    }
    return this.http.post<any>(this.registrationApi + params, order).toPromise();
  }

  async forfaitRegistration(registration: Registration, subscription: Subscription) {
    let params = registration.hash + '/forfait';
    if (registration.signer && registration.signature) {
      params = params + '?signer=' + registration.signer + '&signature=' + registration.signature;
    }
    return this.http.post<any>(this.registrationApi + params, subscription).toPromise();
  }

  async freeRegistration(registration: Registration) {
    let params = registration.hash + '/free';
    if (registration.signer && registration.signature) {
      params = params + '?signer=' + registration.signer + '&signature=' + registration.signature;
    }
    return this.authService.getToken().pipe(switchMap(
      token => this.http.post<any>(
        this.registrationApi + params,
        null,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer '.concat(token.getValue())
          })
        })
    )).toPromise();
  }

  async signRegistration(registration: Registration, wallet: Wallet | string, sk?: string) {
    if (registration.blockchainType === 'ETHEREUM' && !(typeof wallet === 'string')) {
      const contractAddress = (await this.transactionService.contractAddress().toPromise()).address;
      // See the note in the Solidity; basically this would save 6 gas and
      // can potentially add security vulnerabilities in the future
      // let payloadHash = ethers.utils.solidityKeccak256([ "bytes32", "string" ], [ someHash, someDescr ]);
      const payloadHash = ethers.utils.solidityKeccak256(
        ['bytes32', 'string', 'address'],
        ['0x' + registration.hash, registration.description, contractAddress]);
      const payload = ethers.utils.arrayify(payloadHash);
      const signedPayload = await wallet.signMessage(payload);
      const ethAddress = ethers.utils.verifyMessage(payload, signedPayload);
      if (ethAddress !== wallet.address) {
        throw new Error('recovered_address !== wallet.address:\r\n' + ethAddress + '\r\n' + wallet.address);
      }
      registration.signer = wallet.address;
      registration.signature = signedPayload;
      return [wallet.address, signedPayload];
    } else {
      const {hash} = registration;
      // @ts-expect-error
      const skArray = Uint8Array.from(sk.split(','));
      const signedPayload = await ed.sign(hash, skArray.slice(0, 32));

      registration.signer = wallet as string;
      registration.signature = signedPayload;
      return [wallet, signedPayload];
    }
  }

  async verifyRegistration(note: any, hash: string, blockchainType: string, wallet: string) {
    if (blockchainType === 'ETHEREUM' && !(typeof wallet === 'string')) {

    } else {
      var ret=false;
      const noteAsJson = JSON.parse(note)
      if (!noteAsJson) return false;
      try{
      const encryptedHash = noteAsJson.signature;
      const {publicKey} = algosdk.decodeAddress(wallet);
      ret=await ed.verify(encryptedHash, hash, publicKey);
      }catch{

      }
      return ret;

    }
  }

  async custody(file: File) {
    const pid=localStorage.getItem('pratSel');
    const token = (await this.authService.getToken().toPromise());
    const metadata: Metadata = {
      hash: this.registration().getValue().hash,
      name: file.name,
      type: file.type,
      size: file.size,
      lastModified: file.lastModified,
      userId: token.getPayload().userId,
      practice_id:pid,
      walletName: 'null--' + new Date().toISOString() + '--' + sha256(file.name)
    };
    const encryptedBlob = await this.fileService.encrypt(file, 'null');
    const formData = new FormData();
    formData.append('file', encryptedBlob, metadata.hash + '.encrypted');
    await this.fileService.uploadMetadata(metadata, token.getValue()).toPromise();
    await this.fileService.uploadContent(metadata, formData, token.getValue()).toPromise();
  }

  isAwaitingSignature(hash: string) {
    return this.authService.getToken().pipe(switchMap(
      token => this.http.get<boolean>(
        this.registrationApi + hash + '/awaiting-signature',
        {
          headers: new HttpHeaders({
            Authorization: 'Bearer '.concat(token.getValue())
          })
        })
    ));
  }

  requestOTPCode(hash: string) {
    return this.authService.getToken().pipe(switchMap(
      token => this.http.post<any>(
        this.registrationApi + hash + '/otp',
        null,
        {
          headers: new HttpHeaders({
            Authorization: 'Bearer '.concat(token.getValue())
          })
        })
    ));
  }

  verifyOTPCode(hash: string, otp: string) {
    return this.authService.getToken().pipe(switchMap(
      token => this.http.get<any>(
        this.registrationApi + hash + '/otp' + '?otp=' + otp,
        {
          headers: new HttpHeaders({
            Authorization: 'Bearer '.concat(token.getValue())
          })
        })
    ));
  }
}

