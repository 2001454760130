import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NbGlobalPhysicalPosition, NbThemeService, NbToastrService} from '@nebular/theme';
import {NbAuthJWTToken, NbAuthService} from '@nebular/auth';
import {VerificationService} from '../../services/verification.service';
import {Subscription} from '../../../user/models/Subscription';
import {PaymentsService} from '../../../user/services/payments.service';
declare var Stripe: any;
import {environment} from '../../../../environments/environment';

import {Md5} from 'ts-md5/dist/md5';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'dedit-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})
export class DeditPlansComponent implements OnInit {
  option: any = 'algorand';
  flipped = true;
  tabletOrDesktop: boolean;
  desktop: boolean;
  tablet: boolean;
  mobile: boolean;
  vw1500px: boolean;
  user;

  canRefer = false;
  hasReferred = false;
  wasReferred = false;
  l1ReferralEmail: any;

  subscription: any;
  private stripe: any;
  constructor(private router: Router,
              private authService: NbAuthService,
              private route: ActivatedRoute,
              private verificationService: VerificationService,
              private toastrService: NbToastrService,
              private ref: ChangeDetectorRef,
              private paymentsService: PaymentsService,
              private _snackBar: MatSnackBar,
              private themeService: NbThemeService) { }

  ngOnInit() {
    this.stripe = Stripe(environment.stripe);

    this.paymentsService.activeSubscription().toPromise().then(sub => {
      this.subscription = sub;

    }).catch(err => this.subscription = undefined);

    this.themeService.onMediaQueryChange().subscribe(breakpoints => {
      this.tabletOrDesktop = breakpoints[1].width > 991;
      this.desktop = breakpoints[1].width > 91199;
      this.tablet = breakpoints[1].width > 991;
      this.mobile = breakpoints[1].width < 401;
      this.vw1500px = breakpoints[1].width > 1500;
    });

    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable
        } else {
          this.user = undefined;
        }
      });
    // Check whether to display special plans
    // @ts-ignore
    this.route.queryParams.subscribe(params => {
      this.canRefer = params.referral === true;
      const refCode = params.referralCode;
      if (refCode && refCode.includes('VOID')) {
        // process it
        this.wasReferred = true;
      }
    });
  }

  async send() {
    const token = (await this.authService.getToken().toPromise());

    const md5 = new Md5();
    const code = md5.appendStr(Math.random().toString()).end();
    this.verificationService.sendEmail(
      this.l1ReferralEmail,
      'Dedit Referral Link - Iscriviti ad un prezzo speciale',
      // @ts-ignore
      this.user.email + ' ti ha inviato ad iscriverti a Dedit con un piano speciale, ' +
      'per un periodo limitato di tempo. ' +
      'Registrati a dedit qui: ' + encodeURIComponent('https://'+environment.wlv+'.dedit.io/auth/register') +
      '. Dopo aver verificato il tuo account, clicca sul sequente link per approfittare dell\'offerta: ' +
      encodeURIComponent('https://'+environment.wlv+'.dedit.io/plans?referralCode=' + 'DEDIT-VOID' + code),
      token.getValue())
      .toPromise()
      .then(res1 => {
        this.showToast(
          'success',
          'Il referral link è stato inviato',
          'Ora hai accesso ad un piano speciale'
          );

        this.hasReferred = true;
        this.ref.detectChanges();

      })
      .catch(e => console.log(e));
  }

  async subscribe() {
    if (this.subscription && this.subscription.status === 'active') {
      // tslint:disable-next-line:max-line-length
      this.showToast('warning',
        'Dedit non ha potuto iscriverti',
        'Devi prima annullare l\'iscrizione al piano: ' + this.subscription.planNickname, 15000);
      return;
    }

    this.paymentsService.createSubscription(environment.plans.algorand.special)
    .toPromise()
      .then(async sub => {
        const {error} = await this.stripe.redirectToCheckout({
          sessionId: sub.sessionId
        });
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `error.message`.
        this.showToast('error', 'L\'esperienza di Stripe Checkout non funziona', {error}.error.message, 15000);
      }).catch(err1 => console.log(err1));
  }

  unsubscribe() {
    if (this.subscription && this.subscription.status === 'active') {
      this.paymentsService.cancelSubscription(this.subscription.sessionId).toPromise()
      .then(
        sub => {
          this.subscription = sub;
          if (sub.status === 'canceled') {
            // tslint:disable-next-line:max-line-length
            this.showToast('info', 'Dedit ha cancellato la tua iscrizione', 'a: ' + sub.planNickname, 15000);
          }
        });
      }
  }

  private showToast(status, title, message, duration?) {
    /*    this.toastrService.show(message, title,
          duration ? {
            status,
            duration,
            position: NbGlobalPhysicalPosition.BOTTOM_RIGHT
          } : {
            status,
            duration: 0,
            destroyByClick: true,
            position: NbGlobalPhysicalPosition.BOTTOM_RIGHT
          });*/

    this._snackBar.open(title + '. ' + message, status.toUpperCase(), {
      duration: duration ? duration : 5000, panelClass: ['blue-snackbar']
    });
  }
}
