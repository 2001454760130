import {Injectable} from '@angular/core';
import {Metadata} from '../models/Metadata';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {switchMap} from 'rxjs/operators';
import {NbAuthService} from '@nebular/auth';
import {Meta} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  private fileApi = environment.api + '/file/';

  constructor(protected http: HttpClient, private authService: NbAuthService) { }

  public uploadMetadata(metadata: Metadata, jwt): Observable<any> {
    return this.http.put<HttpResponse<any>>(this.fileApi + metadata.hash, metadata, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer '.concat(jwt) })
      });
  }

  public uploadContent(metadata: Metadata, formData: FormData, jwt): Observable<any> {
    return this.http.put<any>(this.fileApi + metadata.hash + '/content', formData, {
      headers: new HttpHeaders({
        Authorization: 'Bearer '.concat(jwt)
      })
    });
  }

  public countMetadata(jwt): Observable<any> {
    return this.http.get<any>(this.fileApi + 'count', {
      headers: new HttpHeaders({
        Authorization: 'Bearer '.concat(jwt)
      })
    });
  }

  public forfaitFileMetadata(hash): Observable<Metadata> {
    return this.authService.getToken().pipe(switchMap(
      token => this.http.get<Metadata>(
        this.fileApi + hash + '/forfaitMetadata',
        {
          headers: new HttpHeaders({
            Authorization: 'Bearer '.concat(token.getValue())
          }),
          responseType: 'json'
        })
    ));
  }

  public forfaitFileContent(hash) {
    return this.authService.getToken().pipe(switchMap(
      token => this.http.get(
        this.fileApi + hash + '/forfaitContent',
        {
          headers: new HttpHeaders({
            Authorization: 'Bearer '.concat(token.getValue())
          }),
          responseType: 'arraybuffer'
        })
    ));
  }

  encrypt(file: File, secret: string) {
    return new Promise<any>(((resolve, reject) => {
      if (typeof Worker !== 'undefined') {
        // Create a new
        const worker = new Worker('./file.worker', { name: 'AES', type: 'module' });
        worker.onmessage = ({ data }) => resolve(data);
        worker.postMessage({ file, secret });
      } else {
        // Web Workers are not supported in this environment.
        // You should add a fallback so that your program still executes correctly.
        reject('Web Workers are not supported in this environment.');
      }
    }));
  }
}
