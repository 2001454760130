import {Injectable} from '@angular/core';
import {sha256} from 'js-sha256';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  private readonly fileBehaviorSubject: BehaviorSubject<File>;

  constructor(protected router: Router) {
    this.fileBehaviorSubject = new BehaviorSubject<File>(null);
  }

  file() {
    return this.fileBehaviorSubject;
  }

  sha256(f: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const uint8Array = reader.result as Uint8Array;
        const hash = sha256(uint8Array);
        resolve(hash);
      };
      reader.onerror = (e) => {
        console.error(e);
        reject(e);
      };
      reader.readAsArrayBuffer(f);
    });
  }

  sha256Worker(f: File) {
    return new Promise<any>((resolve => {
      const worker = new Worker('./upload.worker', { name: 'SHA', type: 'module' });
      worker.onmessage = ({ data }) => resolve(data);
      worker.postMessage({ f });
    }));
  }
}
