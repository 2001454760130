import {AfterViewInit, ChangeDetectionStrategy, Component, HostListener, OnInit} from '@angular/core';
import {IParams} from 'angular-particle/lib';
import {NbMenuItem, NbMenuService, NbPosition, NbSidebarService, NbThemeService, NbTrigger} from '@nebular/theme';
import {filter, map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {NbAuthJWTToken, NbAuthService} from '@nebular/auth';
import {User} from './user/models/User';
import {TranslateService} from '@ngx-translate/core';
import { int } from 'aws-sdk/clients/datapipeline';
import { UserService } from './user/services/user.service';
import IdleTimer from "./IdleTimer";

@Component({
  selector: 'dedit-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, AfterViewInit {

 
  title = 'Notarizzazione BNL';
  myStyle: object = {};
  myParams: object = {};

  hover: NbTrigger = NbTrigger.HOVER;
  bottom: NbPosition = NbPosition.BOTTOM;
  items: NbMenuItem[] = [];

  user: User;
  tabletOrDesktop: boolean;
  desktop: boolean;
  tablet: boolean;
  mobile: boolean;
  mobileOnly: boolean;
  pratSel:string;
  logex:string;
  private static getDefaultParams(): IParams {
      return {
        particles: {
          number: {
            value: 100,
            density: {
              enable: true,
              value_area: 1000
            }
          },
          color: {
            value: '#FFF'
          },
          shape: {
            type: 'circle',
            stroke: {
              width: 0,
              color: '#000000'
            },
            polygon: {
              nb_sides: 3
            },
            image: {
              src: '',
              width: 100,
              height: 100
            }
          },
          opacity: {
            value: 0.77,
            random: false,
            anim: {
              enable: true,
              speed: 1,
              opacity_min: 0.11,
              sync: false
            }
          },
          size: {
            value: 1.5,
            random: true,
            anim: {
              enable: false,
              speed: 40,
              size_min: 0,
              sync: false
            }
          },
          line_linked: {
            enable: false,
            distance: 100,
            color: '#FFF',
            opacity: 0.6,
            width: 0.25,
            shadow: {
              enable: false,
              blur: 5,
              color: 'black'
            }
          },
          move: {
            enable: true,
            speed: 0.6,
            direction: 'none',
            random: true,
            straight: false,
            out_mode: 'out',
            bounce: false,
            attract: {
              enable: false,
              rotateX: 3000,
              rotateY: 3000
            }
          },
          array: []
        },
        interactivity: {
          detect_on: 'canvas',
          events: {
            onhover: {
              enable: true,
              mode: 'grab'
            },
            onclick: {
              enable: true,
              mode: 'push'
            },
            resize: true
          },
          modes: {
            grab: {
              distance: 100,
              line_linked: {
                opacity: 1
              }
            },
            bubble: {
              distance: 400,
              size: 8,
              duration: 2
            },
            repulse: {
              distance: 100,
              duration: 4
            },
            push: {
              particles_nb: 10
            },
            remove: {
              particles_nb: 2
            }
          },
          mouse: {}
        },
        retina_detect: true
      };
    }
  
    isOpt:boolean;

  constructor(protected router: Router,
              private nbMenuService: NbMenuService,
              private authService: NbAuthService,
              private themeService: NbThemeService,
              private translate: TranslateService,
              private sidebarService: NbSidebarService,
              private userService: UserService
              ) {
                this.logex=localStorage.getItem('logex');
                this.isOpt=(localStorage.getItem('isOpt')==null?true:false);
 /*
                const timer = new IdleTimer({
                  timeout: 1800, //expire after 30 minuti
                  onTimeout: () => {
                    //setIsTimeout(true);
                    //alert('aaa');
                  },
                  onExpired: () => {
                    // do something if expired on load
                    //setIsTimeout(true);  
                    alert('Sessione scaduta!!!');
                    localStorage.clear();
                  }
                });
*/
    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable
          localStorage.setItem('email',this.user.email);
          this.userService.user().toPromise().then(u => {
            localStorage.setItem('roleid',u.roleid);
            localStorage.setItem('groupid',u.groupid);
        
          
      
          });
          
        
        } else {
          this.user = undefined;
        }
      });

    translate.addLangs(['it', 'en', 'es']);
    translate.setDefaultLang('en');
    const language = translate.getBrowserLang();
    translate.use(language.match(/it|en|es/) ? language : 'en');

    this.items = [
      {
        title: 'Home',
        icon: { icon: 'home-outline', pack: 'eva' },
        link: '/',
      },
      {
        title: translate.currentLang === 'it' ? 'Pratiche' : 'Practices',
        icon: { icon: 'archive-outline', pack: 'eva' },
        link: '/user/practices',
      },
      /*
      {
        title: translate.currentLang === 'it' ? 'Registrazioni' : 'Registrations',
        icon: { icon: 'archive-outline', pack: 'eva' },
        link: '/user/registrations',
      },
      {
        title: translate.currentLang === 'it' ? 'Custodia' : 'Vault',
        icon: 'lock-outline',
        link: '/user/files',
      },
      {
        title: translate.currentLang === 'it' ? 'Pagamenti' : 'Payments',
        icon: { icon: 'shopping-cart-outline', pack: 'eva' },
        link: '/user/payments',
      },
      {
        title: 'Account',
        icon: 'person-outline',
        link: '/user',
      },*/
      {
        title: translate.currentLang === 'it' ? 'Esci' : 'Logout',
        icon: 'person-remove-outline',
        link: '/auth/logout',
      },
    ];
  }

  ngOnInit() {
    this.themeService.onMediaQueryChange().subscribe(breakpoints => {
      this.tabletOrDesktop = breakpoints[1].width > 991;
      this.mobile = breakpoints[1].width <= 991;
      this.desktop = breakpoints[1].width > 91554;
      this.mobileOnly = breakpoints[1].width < 401;
    });
    this.pratSel=localStorage.getItem('pratSel');
    this.myStyle = {
      position: 'absolute',
      width: '100%',
      height: '111px',
      'z-index': 0,
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    };
    this.myParams = AppComponent.getDefaultParams();

    this.nbMenuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'user'),
        map(({ item: { title } }) => title),
      )
      .subscribe(title => {
        switch (title) {
          
          case 'Log in': this.router.navigate(['auth/login']); break;
          case 'Dashboard': this.router.navigate(['user']); break;
          case 'Log out': this.router.navigate(['auth/logout']); break;
          case 'Register': this.router.navigate(['auth/register']); break;
          case 'Registrations': this.router.navigate(['user/registrations']); break;
          case 'Wallets': this.router.navigate(['user/wallets']); break;
          case 'Files': this.router.navigate(['user/files']); break;
          case 'Payments': this.router.navigate(['user/payments']); break;
          case 'Plans': this.router.navigate(['user/plans']); break;
          case 'Organizations': this.router.navigate(['user/organizations']); break;
          case 'Settings': this.router.navigate(['user/settings']); break;
          case 'Practices': this.router.navigate(['user/practices']); break;
        }
      });
  }

  toggle() {
    // this.sidebarService.toggle(false);
    if (this.router.url === '/' || this.router.url === '/home') {
      this.router.navigate(['user']);
    } else if ( this.router.url === '/user/registrations' || this.router.url === '/user/wallets' ||
                this.router.url === '/user/files' || this.router.url === '/user/payments' ||
                this.router.url === '/user/plans' || this.router.url === '/user/settings' ||
                this.router.url === '/user/practices') {
      this.router.navigate(['user']);
    } else {
      this.router.navigate(['/']);
    }
  }

  ngAfterViewInit(): void {
    this.sidebarService.collapse();
  }

  
}
