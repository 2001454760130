export const environment = {
  production: true,
  supportTo: 'help@dedit.io',
  supportBcc: 'emanuela.bevilacqua@bnpparibas.com',
  wlv: window.location.hostname.split('.')[0],
  api: '/api/v2',
  paypal: 'AY2y4DTvaD_eftECds3SuKHQG8CJfmmP9zl7BrblUCl7h1svpwFBASQBNNQwDb-7EYW0QT1hFZFQUBIl',
  stripe: 'pk_live_ZEsqDx7LFprU7aCDTYNKLfeF00KK6jF2ql',
  plans: {
    ethereum: {
      starter: 'price_HJvMJ6pMGxyymJ',
      medium: 'price_HJvMiO3CJqQI0D',
      pro: 'price_HJvMBwnnxzKySZ',
    },
    algorand: {
      starter: 'price_HLJwqffu7Em4dx',
      medium: 'price_1HsQM1GkNaUEmNJUOTWsZAqz',
      pro: 'price_1HrPUWGkNaUEmNJUHgql92GS',
      special: 'price_1HrPXzGkNaUEmNJUDV4ctSao',
      mydeditplan: 'price_1IntOCGkNaUEmNJUlduYqXPM'
    }
  },
  ethereumEnv: 'mainnet',
  ethereumProvider: '1501cd1504d04a799ac3773b1db5f6ec',
  algorandEnv: 'https://mainnet-idx.algonode.cloud',
  port: '',
  cacheBusterHash: Math.random().toString(36).substring(7),
  keycloak: {
    config: {
      url: 'https://account.dedit.io/auth',
      realm: 'Dedit-account',
      clientId: 'dedit-account',
      redirectUri: 'https://bnl.dedit.io'
    },
    initOptions: {
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri:
        window.location.origin + '/assets/silent-check-sso.html',
    },
  },
  "private-instance": true,
  "extended-verification-fields": true
};