import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {EncryptedWallet} from '../models/EncryptedWallet';
import {Observable} from 'rxjs';
import {NbAuthService} from '@nebular/auth';
import {switchMap} from 'rxjs/operators';
import {Wallet} from 'ethers';

@Injectable({
  providedIn: 'root'
})
export class WalletService {
  private walletApi = environment.api + '/wallet/';

  constructor(private http: HttpClient, private authService: NbAuthService) { }

  activeWallet(): Observable<EncryptedWallet> {
    return this.authService.getToken().pipe(switchMap(
      token => this.http.get<EncryptedWallet>(
        this.walletApi + 'active',
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer '.concat(token.getValue())
          })
        })
    ));
  }

  newActiveWallet(wallet: any, jwt: string): Observable<any> {
    return this.http.post<any>(
        this.walletApi + 'create',
        wallet,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer '.concat(jwt)
          })
        });
  }

  getWallet(walletType : string): Observable<EncryptedWallet> {
    return this.authService.getToken().pipe(switchMap(
      token => this.http.get<EncryptedWallet>(
        this.walletApi + 'getwallet/'+walletType,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer '.concat(token.getValue())
          })
        })
    ));
  }
}
