import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {NbAuthService} from '@nebular/auth';
import {switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  private transactionApi = environment.api + '/transaction/';

  constructor(private http: HttpClient, private authService: NbAuthService) { }

  registrationTx(hash) {
    return this.http.get<any>(this.transactionApi + hash, { observe: 'response' });
  }

  registrationTxAll(hash) {
    return this.http.get<any>(this.transactionApi +'firma/'+ hash, { observe: 'response' });
  }
  contractAddress(): Observable<any> {
    return this.authService.getToken().pipe(switchMap(
      token => this.http.get<any>(
        this.transactionApi + 'address',
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: 'Bearer '.concat(token.getValue())
          })
        })
    ));
  }
}
