import {ChangeDetectorRef, Component, OnInit, TemplateRef} from '@angular/core';
import {VerificationService} from '../../services/verification.service';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {switchMap} from 'rxjs/operators';
import {Registration} from '../../models/Registration';
import {TransactionService} from '../../services/transaction.service';
import {ethers} from 'ethers';
import {environment} from '../../../../environments/environment';
import {NbAuthJWTToken, NbAuthService} from '@nebular/auth';
import {User} from '../../../user/models/User';

// Imports PDFMake.js
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import {NbDialogService, NbThemeService, NbToastrService} from '@nebular/theme';
import {UserSignature} from '../../../user/models/UserSignature';
import {WalletService} from '../../../auth/services/wallet.service';
import {FileService} from '../../services/file.service';
import {Metadata} from '../../models/Metadata';
import {UploadService} from '../../services/upload.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {RegistrationService} from '../../services/registration.service';
import {PaymentsService} from '../../../user/services/payments.service';
import {Subscription} from '../../../user/models/Subscription';
import {RegistrationsService} from "../../../user/services/registrations.service";
import { isNullOrUndefined } from 'util';
import * as ed from 'noble-ed25519';
import CryptoAES from 'crypto-js/aes';
import crypto from 'crypto-js';

@Component({
  selector: 'dedit-verify',
  templateUrl: './verification.component.html',
  styleUrls: ['./verification.component.scss']
})
export class DeditVerificationComponent implements OnInit {
  registration: Registration;
  algorand;
  ethereum;
  ethereumProvider;
  verifying: boolean;
  whois = {
    known: false,
    address: '',
  };
  //
  user: any;
  emailItems: string[];
  tabletOrDesktop: boolean;
  mobile: boolean;
  // tslint:disable-next-line:max-line-length

  transport: any = {};
  flipped = false;

  multisig: any = {};
  signers: any = new Array<string>();
  signbox = false;
  otp = '';
  showOtpInput = false;
  isOtpVerified = false;
  identityCard: string;
  multisigTerms = false;
  signboard = {
    signers: [],
    master: null
  };

  cleanSignboard = '';

  messages: string[];
  errors: string[];
  downloading: boolean;
  isFileThere = false;
  fileScaricato = false;
  

  isCorrectSignature = false;
  private activeSub: Subscription;
  private count: number;
  private signing: boolean;
  private otpDialog: any;

  extendedVerificationFields: boolean;

  rightfulSigner: boolean;
  forceShowFile: boolean;

  pratSel:string;
  pratSelDescr:string;
  abilitaRegistra:string;
  roleId:string;
  listafirma:any;
  
  statoReg:string;


  constructor(private dialogService: NbDialogService,
              private registrationsService: RegistrationsService,
              private router: Router,
              private toastrService: NbToastrService,
              // tslint:disable-next-line:max-line-length
              private walletService: WalletService,
              private ref: ChangeDetectorRef,
              private filesService: FileService,
              private uploadService: UploadService,
              // tslint:disable-next-line:max-line-length
              private cdRef: ChangeDetectorRef,
              private route: ActivatedRoute,
              private _snackBar: MatSnackBar,
              private themeService: NbThemeService,
              private translate: TranslateService,
              private registrationService: RegistrationService,
              private paymentsService: PaymentsService,
              private verificationService: VerificationService, private txService: TransactionService, private authService: NbAuthService) {
    this.registration = {} as Registration;
    this.verifying = false;
    this.ethereumProvider = new ethers.providers.InfuraProvider(environment.ethereumEnv, environment.ethereumProvider);

    this.extendedVerificationFields = environment["extended-verification-fields"]
    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {
        if (token.isValid()) {
          this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable
          console.log(this.user);
        } else {
          this.user = undefined;
        }
      });

    translate.addLangs(['it', 'en', 'es']);
    translate.setDefaultLang('en');
    const language = translate.getBrowserLang();
    translate.use(language.match(/it|en|es/) ? language : 'en');
    if (translate.currentLang === 'es') {
      this.emailItems = [
        ' le envió el recibo del registro del documento: ',
        ' que certificó con Dedit para darle una fecha determinada, prueba de autenticidad y paternidad.\n',
        'Al escanear el código QR en el certificado, podrá ver los detalles de registro. ',
        'Prueba dedit.io: '
      ]
    } else {
      this.emailItems = [
        translate.currentLang === 'it' ? ' ti ha inviato la ricevuta della registrazione del documento: ' : ' has sent you the receipt of the registration of the document: ',
        translate.currentLang === 'it' ? ' che ha notarizzato con Dedit per dargli data certa, prova di autenticità e paternità.\n' : ' which he notarized with Dedit to give him a certain date, proof of authenticity and paternity.\n',
        translate.currentLang === 'it' ? 'Scannerizzando il codice QR riportato sul certificato, potrai visualizzare i dettagli della registrazione. ' : ' By scanning the QR code on the certificate, you will be able to view the registration details. ',
        translate.currentLang === 'it' ? 'Prova anche tu dedit.io: ' : 'Try dedit.io: '
      ]
    }
  }

  ngOnInit() {
    // tslint:disable-next-line:variable-name
    const __file__ = !(this.uploadService.file().getValue());
    this.isFileThere = !__file__;
    this.multisig.link = '';

    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        const hash = params.get('hash');
        this.verifying = true;
        this.registration.hash = hash;
        // check
        return this.verificationService.verify(hash);
      })).subscribe(
      doc => {
        this.registration = doc;
        if (this.registration.status === 'registered' && this.registration.blockchainType === 'ETHEREUM') {
          this.txService.registrationTx(this.registration.hash).subscribe(
            txRes => {
              this.ethereum = {registrant: txRes.body.value1};
              this.knownRegistrant(this.ethereum.registrant);
              this.ethereumProvider.getTransaction(txRes.headers.get('tx')).then(
                tx => {
                  this.ethereum.tx = tx;
                  this.ethereumProvider.getBlock(tx.blockNumber).then(
                    block => {
                      this.ethereum.block = block;
                      this.verifying = false;
                      this.cdRef.detectChanges();
                    });
                },
                txErr => {
                  console.log(txErr);
                  this.verifying = false;
                  this.cdRef.detectChanges();
                });
            }, err => {
              console.log(err);
              this.verifying = false;
              this.cdRef.detectChanges();
            });
        } else if (this.user && this.registration.status === 'registered' && this.registration.blockchainType === 'ALGORAND') {
          this.txService.registrationTx(this.registration.hash).toPromise().then(async (algoTx) => {
            this.algorand = algoTx.body;
            if (this.registration.signed) {
              const algosdk = require('algosdk');
              const {transaction_hash, hash} = algoTx.body;
              const baseServer = environment.algorandEnv;
              const port = environment.port;
              const algodclient = new algosdk.Indexer("", baseServer, port);
              const {transactions} = await algodclient.searchForTransactions().txid(transaction_hash).do();
              if (transactions.length === 1) {
                const {note} = transactions[0]
                const algorandWallet = await this.walletService.getWallet('algorand').toPromise();
                const address = algorandWallet.name;
                const sigCheck = await this.registrationService.verifyRegistration(atob(note), hash, 'ALGORAND', address);
                if (sigCheck != null || sigCheck !== undefined) this.isCorrectSignature = sigCheck;
              }
              this.algorand.from_address = this.registration.signer;
              if(this.algorand.from_address==null){
                this.algorand.from_address ='                                                      ';
              }
            }
            this.verifying = false;
            this.cdRef.detectChanges();
          });
        } else {
          this.verifying = false;
          this.cdRef.detectChanges();
        }

        // check sigs
        this.populateSignboard().then(res => {
          this.isRegistrationAwaitingSignature().then(res1 => {})
        });
      },
      err => {
        if (err.status === 406) {
          this.verifying = false;
          this.registration.status = 'not_registered';
          this.cdRef.detectChanges();
        } else {
          // todo: inform user/dev about unforeseen errors
          console.log(err);
        }
      });
    this.themeService.onMediaQueryChange().subscribe(breakpoints => {
      this.tabletOrDesktop = breakpoints[1].width > 991;
      this.mobile = breakpoints[1].width < 401;
    });

    // establish master anyway
    this.populateMaster();
    this.pratSelDescr=localStorage.getItem('pratSelDescr');
    this.pratSel=localStorage.getItem('pratSel');
    this.roleId=localStorage.getItem('roleid');
     
    if(localStorage.getItem('roleid')=="0"){
     this.abilitaRegistra=(isNullOrUndefined(this.pratSel)?"0":"1");
    }else{
     this.abilitaRegistra="1";
    }
  }


  private showToast(status, title, message, duration?) {
    /*    this.toastrService.show(message, title,
          duration ? {
            status,
            duration,
            position: NbGlobalPhysicalPosition.BOTTOM_RIGHT
          } : {
            status,
            duration: 0,
            destroyByClick: true,
            position: NbGlobalPhysicalPosition.BOTTOM_RIGHT
          });*/

    this._snackBar.open(title + '. ' + message, status.toUpperCase(), {
      duration: duration ? duration : 5000, panelClass: ['blue-snackbar']
    });
  }

  async populateMaster() {
    if (this.user) {
      const reg: Registration = await this.verificationService.verifyWithAuth(this.registration.hash).toPromise();
      if (reg.userId === this.user.userId) {
        // @ts-ignore
        this.signboard.master = this.user;
      } else {
        this.signboard.master = await this.verificationService.getUser(reg.userId).toPromise();
      }
    
    
      this.listafirma= await (await this.txService.registrationTxAll(this.registration.hash).toPromise()).body;

    }


  }

  flip() {
    this.flipped = !this.flipped;
  }

  retryforfaitregistration(): void {
    this.verifying = true;
    this.ref.detectChanges();
    this.paymentsService.activeSubscription().toPromise().then(aS => {
      this.activeSub = aS;
      this.registrationService.registrationCount().toPromise()
        .then(res => res.registrationsLimit - res.registrationsUsed)
    })
      .catch(err => console.log('No active sub: ' + err)).then(r =>
      this.registrationService.forfaitRegistration(this.registration, this.activeSub).then(
        reg => {
          this.verifying = false;
          window.location.reload();
        },
        err => {
          this.errors.push(err);
        }
      )
    );
  }

  private knownRegistrant(registrant: string) {
    switch (registrant) {
      case '0xcfa97bceff041b49684a668bfb90e1d15ab4371e':
        this.whois.known = true;
        this.whois.address = registrant;
        break;
      case '0x9e923317d85a7be90462dc599fe832b73f2c6b52':
        this.whois.known = true;
        this.whois.address = registrant;
        break;
      case '0x0e32465303f26d803170ce92249d10c70c56693f':
        this.whois.known = true;
        this.whois.address = registrant;
        break;
      default:
        this.whois.known = false;
    }
  }

  registerIntent() {
    this.verificationService.handleRegistrationIntent(this.registration);
  }

  prettifyHash(hash: string): string {
    hash = hash.replace('0x', '');
    const hash1 = hash.slice(0, hash.length / 2);
    const hash2 = hash.slice(hash.length / 2, hash.length);
    const hash11 = hash1.slice(0, hash1.length / 2).toUpperCase();
    const hash12 = hash1.slice(hash1.length / 2, hash1.length).toUpperCase();
    const hash21 = hash2.slice(0, hash2.length / 2).toUpperCase();
    const hash22 = hash2.slice(hash2.length / 2, hash2.length).toUpperCase();
    return hash11 + hash12 + hash21 + hash22;
  }

  async generatePdf() {

    const user = (await this.authService.getToken().toPromise()).getPayload() as User;
    const contract = (await this.txService.contractAddress().toPromise()).address;
    const regTx = await this.txService.registrationTx(this.registration.hash).toPromise();
    const transactionHash = this.registration.blockchainType === 'ETHEREUM' ? regTx.headers.get('tx') : regTx.body.transaction_hash;
    const hash = this.registration.hash;
    const hash1 = this.prettifyHash(hash);
    const description = this.registration.description;
    const signersString = this.cleanSignboard;
    let multisig = '';
    let multisigSubHeader = '';
    if (signersString.length > 0) {
      multisig = 'MULTISIG';
      multisigSubHeader = 'This registration was signed by';
    }
    const name = user.firstName + ' ' + user.lastName;
    const timestamp = this.registration.creationDate;
    // tslint:disable-next-line:max-line-length
    const qrCodeVerify = (environment.ethereumEnv === 'testnet' ? 'http://localhost:8080/verification/' : 'https://' + environment.wlv + '.dedit.io/verification/') + hash;
    // tslint:disable-next-line:max-line-length
    const qrCodeTransaction = this.registration.blockchainType === 'ETHEREUM' ? ((environment.ethereumEnv === 'testnet' ? 'https://ropsten.etherscan.io/tx/' : 'https://etherscan.io/tx/') + transactionHash)
      : 'https://app.dappflow.org/explorer/transaction/' + transactionHash;

    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    pdfMake.fonts = {
      FuturaBook: {
        normal: 'Futura.ttf',
        bold: 'Futura.ttf',
      },

      // example of usage fonts in collection
      Avenir: {
        normal: 'Avenir.ttf',
        bold: 'Avenir.ttf',
      },
      Roboto: {
        normal: 'Roboto-Regular.ttf',
        bold: 'Roboto-Medium.ttf',
        italics: 'Roboto-Italic.ttf',
        bolditalics: 'Roboto-MediumItalic.ttf'
      }
    };

    const time = new Date(this.registration.creationDate);
    const docDefinition = {
      // pageSize: 'A4',
      pageMargins: [40, 60, 40, 0],
      
      background(currentPage, pageSize) {
        return [
          {image: 'template.png', width: pageSize.width, height: pageSize.height},
        ];
      },
     
      content: [
        // tslint:disable-next-line:max-line-length
        this.registration.blockchainType === 'ETHEREUM' ? {
          text: 'SMART CONTRACT',
          color: 'white',
          absolutePosition: {x: 74, y: 14},
          fontSize: 6
        } : {},
        // tslint:disable-next-line:max-line-length
        this.registration.blockchainType === 'ETHEREUM' ? {
          text: contract,
          color: 'white',
          absolutePosition: {x: 74, y: 22},
          fontSize: 5
        } : {},
        // tslint:disable-next-line:max-line-length
        {
          text: hash1,
          link: `https://` + environment.wlv + `.dedit.io/verification/${hash1}`,
          style: 'hash',
          color: 'white',
          absolutePosition: {x: 50, y: 260},
          fontSize: 9,
          alignment: 'center'
        },
        {text: description, color: 'white', absolutePosition: {x: 50, y: 415}, alignment: 'center'},
        {text: multisigSubHeader, color: 'white', absolutePosition: {x: 50, y: 465}, alignment: 'center', fontSize: 16},
        {text: signersString, color: 'white', absolutePosition: {x: 50, y: 495}, alignment: 'center'},
        /*{ qr: 'text in QR', absolutePosition: {x: 170, y: 700} },
        { qr: 'text in QR', absolutePosition: {x: 370, y: 700} },*/
        {
          absolutePosition: {x: -225, y: 570},
          columns: [
            [
              {
                text: this.signboard.master.firstName + ' ' + this.signboard.master.lastName,
                style: 'firstname_timestamp'
              },
              {text: this.algorand.from_address.slice(0, -29), style: 'firstname_timestamp'},
              {text: this.algorand.from_address.slice(29,), style: 'firstname_timestamp'},
            ],
          ],
        },
        {
          absolutePosition: {x: 315, y: 570},
          columns: [
            [
              {text: timestamp, style: 'firstname_timestamp'},
              {text: time.toDateString() + ' ' + time.toTimeString(), style: 'firstname_timestamp'}
            ],

          ],
        },
        /*{ qr: 'text in QR', absolutePosition: {x: 170, y: 700} },
        { qr: 'text in QR', absolutePosition: {x: 320, y: 700} },
        { text: 'QR Code per la verifica', style:'qrDescription', absolutePosition: {x: 170, y: 810}},
        { text: 'QR Code per la verifica', style:'qrDescription',absolutePosition: {x: 320, y: 810}},*/
        {
          absolutePosition: {x: -225, y: 730},
          columns: [
            [
              {qr: qrCodeVerify, alignment: 'center', fit: 100},
              {text: 'Verification QR Code', style: 'qrDescription'},
            ],]
        },
        {
          absolutePosition: {x: 325, y: 730},
          columns: [
            [

              {qr: qrCodeTransaction, alignment: 'center', fit: 100},
              {text: 'Transaction QR Code', style: 'qrDescription'},

            ],]
        }


      ],
      defaultStyle: {
        font: 'FuturaBook' //FuturaBook Courier | Helvetica | Times | Symbol | ZapfDingbats
      },
      styles: {
        marginTop: {
          margin: [0, 24, 0, 0]
        },
        headerHash: {
          fontSize: 9,
        },
        hash: {
          fontSize: 9,
          alignment: 'center',
        },

        firstname_timestamp: {
          color: '#142269',
          alignment: 'center',
          margin: [0, 0, 0, 0]
        },
        qrDescription: {
          fontSize: 9,
          alignment: 'center',
        },
      },
    };

    return pdfMake.createPdf(docDefinition);
  }

  async downloadPdf() {
    await (await this.generatePdf()).download('Dedit Certificate - ' + this.registration.hash + '.pdf');
  }

  async sendPdf() {

    if (!this.transport || !this.transport.email || !this.transport.subject) {
      this.errors = [];
      this.errors.push('Compila i campi "Destinatario" e "Oggetto" del modulo d\'invio');
      this.showToast(
        'warning',
        'Compila i campi "Destinatario" e "Oggetto" del modulo d\'invio',
        'Modulo d\'invio: campi mancanti');
      return;
    }

    const token = (await this.authService.getToken().toPromise());
    const user = token.getPayload();

    (await this.generatePdf()).getBuffer(buffer => {
      const pdfBlob = new Blob([buffer], {type: 'application/pdf'});

      const formData = new FormData();
      formData.append('CodiceFiscale', '');
      formData.append('file', pdfBlob, 'Dedit Certificate - ' + this.registration.hash + '.pdf');
      formData.append('to', '');
      formData.append('subject', this.transport.subject);
      formData.append('body', user.email + this.emailItems[0] +
        this.registration.description +
        this.emailItems[1] +
        this.emailItems[2] +
        this.emailItems[3] +
        encodeURIComponent(this.multisig.link))

      const potentialEmails: string[] = this.transport.email.split(/\s+/);

      for (const pEmail of potentialEmails) {

        if (!DeditVerificationComponent.validateEmail(pEmail)) {
          this.errors = [];
          this.errors.push('Non abbiamo potuto validare il seguente indirizzo email: \' + pEmail');
          this.showToast('error',
            'Non abbiamo potuto validare il seguente indirizzo email: ' + pEmail,
            'Riprovare con indirizzi email corretti');
          return;
        }

        formData.set('to', pEmail);

  

        this.verificationService.sendCertificate(
          formData,
          token.getValue())
          .then(res => {
            this.messages = [];
            this.messages.push('Il Certificato PDF è stato inviato correttamente');
            this.showToast('success',
              '',
              'Il Certificato PDF è stato inviato correttamente');
          })
          .catch(e => console.log(e));
      }
    });
   
  }

  async sendMultisigLink() {
    const potentialEmails: string[] = this.multisig.invited.split(/\s+/);
    const user = (await this.authService.getToken().toPromise()).getPayload();
    const multisigMaster = user.email;
    const multisigSigners = [];
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < potentialEmails.length; i++) {

      if (!DeditVerificationComponent.validateEmail(potentialEmails[i])) {
        this.errors = [];
        this.errors.push('Non abbiamo potuto validare il seguente indirizzo email: ' + potentialEmails[i]);
        this.showToast('error',
          'Non abbiamo potuto validare il seguente indirizzo email: ' + potentialEmails[i],
          'Riprovare con indirizzi email corretti');
        return;
      }

      multisigSigners.push(potentialEmails[i]);

      // if (multisigSigners.length > 0) {
      this.multisig.link = 'https://' + environment.wlv + '.dedit.io/verification/' + this.registration.hash +
        '?multisigMaster=' + multisigMaster +
        '&multisigSigners=' + multisigSigners;
      this.ref.detectChanges();

      // for (const multisigSigner of multisigSigners) {
      const token = (await this.authService.getToken().toPromise());

      await (await this.generatePdf()).getBuffer(async buffer => {
        const pdfBlob = new Blob([buffer], {type: 'application/pdf'});

        const formData = new FormData();
        formData.append('CodiceFiscale', '');
        formData.append('file', pdfBlob, 'Dedit Certificate - ' + this.registration.hash + '.pdf');
        formData.append('to', potentialEmails[i]);
        formData.append('subject', 'Dedit Multisig');
        formData.append('body', multisigMaster +
          ' ti ha invitato a firmare e a scaricare il documento notarizzato: "' + this.registration.description +
          '" su Dedit! Per apporre la Firma registrati a DEDIT o accedi al sistema e ' +
          'poi clicca sul sequente link per firmare la registrazione e/o scaricare il documento originale: '
          + encodeURIComponent(this.multisig.link));


        // const to = users.filter(u => u.email === multisigSigner)[0];
        // console.log(to);
        //DISABILITO INVITO CLIENTE
          //await this.verificationService.sendCertificate(formData, token.getValue()).catch(e => console.log('Error ', e));
      
        await this.verificationService.sendSignRequest(this.registration.hash, potentialEmails[i], token.getValue()).catch(e => console.log('Error ', e));

        if (i === potentialEmails.length - 1) {
          await console.log('end')
          this.showToast('success',
            'Authorized ' + multisigSigners.length + ' account: ',
            'Refreshing page, please wait');

          this.refreshAfterSign(5000)
        }
      });
    }


  }

  refreshAfterSign(time) {
    setInterval(() => this.router.navigate(['/verification/' + this.registration.hash]).then(() => {
      window.location.reload();
    }), time);
  }

  private static validateEmail(email: string) {
    // tslint:disable-next-line:max-line-length
    const re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    return re.test(email);
  }

  async sign() {

    const encryptedWallet = await this.walletService.getWallet('algorand').toPromise();
/*
    const userSig: UserSignature = {
      userId: this.user.userId,
      hash: this.registration.hash,
      id: this.identityCard,
      terms: this.multisigTerms,
      signature: encryptedWallet.name,
      signer:encryptedWallet.name,
    };
*/
    const token = (await this.authService.getToken().toPromise());

    (await this.generatePdf()).getBuffer(buffer => {
      const pdfBlob = new Blob([buffer], {type: 'application/pdf'});

      const formData = new FormData();
      formData.append('CodiceFiscale','');
      formData.append('file', pdfBlob, 'Dedit Certificate - ' + this.registration.hash + '.pdf');
      formData.append('to',  this.signboard.master.email);
      formData.append('subject', 'Dedit Multisig - Nuova Firma da <' + this.user.lastName + ' '+ this.user.firstName + '('+ this.user.email + ')');
      formData.append('body', this.user.lastName + ' '+ this.user.firstName + '('+ this.user.email + ')' +
        ' ha firmato il documento notarizzato: "' + this.registration.description +
        '" su Dedit! Per controllare le altre Firme accedi al sistema e ' +
        'poi clicca sul sequente link: ' + encodeURIComponent('https://' + environment.wlv + '.dedit.io/verification/' + this.registration.hash),
      );

   
        const masterId = this.route.snapshot.queryParamMap.get('multisigMaster');

      
 
        this.verificationService.sendCertificate(
          formData,
          token.getValue())
          .then(res1 => {
/*
            this.messages = [];
            this.messages.push('Il messaggio è stato inviato correttamente');
            this.showToast('success',
              'ID Messaggio: ' + res1.messageId,
              'Il messaggio è stato inviato correttamente');
*/
          })
          .catch(e => console.log(e));
        /*
        this.messages = [];
        this.messages.push('La registrazione è stata firmata con successo');
        this.showToast('success',
          'La registrazione è stata firmata con successo ',
          'La firma è stata apposta con il borsellino: ' + encryptedWallet.name);
        */}
      );
  }

  async populateSignboard() {
    this.statoReg='DAINVIARE';

    this.signboard.signers = await this.verificationService.getSignatures(this.registration.hash).toPromise();

    for (const signer of this.signboard.signers) {
      this.statoReg='COMPLETA';
      if(signer.signature)
      this.cleanSignboard += signer.firstName + ' ' + signer.lastName +'\n';
      if(!signer.terms)
      this.statoReg='ATTESA';

    }
    if(this.statoReg=='DAINVIARE' && this.registration.tipoPratica=='M2'){
      this.multisig.invited=this.registration.codFiscalePratica;

    }
    // parse multisig signers
    // Get the query string value from our route
    const multisigSigners = this.route.snapshot.queryParamMap.get('multisigSigners');

    if (multisigSigners === null) {
      return;
    }

    const multisigSignersSplit = multisigSigners.split(',');

    // If the value is null, create a new array and store it
    // Else parse the JSON string we sent into an array
    if (multisigSignersSplit === null) {
      this.signers = [];
    } else {
      this.signers = [...multisigSignersSplit];
      await this.signers.forEach(signer => {
        if (this.user.email === signer) {
          this.forceShowFile = true;
          this.signbox = true;
        }
      });
      if (this.signbox === false) {
        localStorage.clear();
        setInterval(() => this.router.navigate(['/auth/login/']).then(() => {
          window.location.reload();
        }), 2000);
      }
    }
  }

  prettifySigner(signer: any) {
    // tslint:disable-next-line:max-line-length
    return signer.firstName + ' ' + signer.lastName + ' <' + signer.email + '> - ' + (signer.id==null?'':signer.id + ' - ') + new Date(signer.creationDate).toISOString();
  }

  clip($event: MouseEvent) {
    if (this.multisig.link && this.multisig.link !== '') {
      /* Get the text field */
      const copyText = document.getElementById('multisigLinker') as HTMLInputElement;

      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand('copy');

      this.toastrService.show(
        'Il link è stato copiato',
        this.multisig.link,
        // @ts-ignore
        {duration: 5000, position: 'bottom-right', status: 'info'});
    }
  }

  async downloadFile() {
    if (!this.signboard.master) {
      this.errors = [];
      this.errors.push('Multisig Master dev\'essere diverso da null.');
      this.showToast('success',
        'Accettare i Termini e le Condizioni del servizio di Multisig',
        'Riprovare dopo aver accettato i Termini e le Condizioni');
      return;
    }

    const metadata: Metadata = await this.filesService.forfaitFileMetadata(this.registration.hash).toPromise();

    this.filesService.forfaitFileContent(this.registration.hash).subscribe(
      decrypted => {
        const blob = new Blob([decrypted], {type: 'arraybuffer'});
        // that.sanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
        this.saveFile(blob, 'Dedit File - ' + metadata.name);
        this.downloading = false;
        this.messages = [];
        this.messages.push('File scaricato con successo');
        this.toastrService.show(
          'File scaricato con successo',
          // @ts-ignore
          {duration: 7500, position: 'bottom-right', status: 'success'});
        this.fileScaricato=true;  
      }, error => {

        this.downloading = false;
        this.errors = [];
        this.errors.push('Problema durante lo scaricamento del file');
        this.showToast('error',
          'Errore durante lo scaricamento del file',
          error.message);
      }
    );
  }

  private saveFile(blob, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }, 0);
    }
  }

  async showOTPModal(emptySdiPecDialog: TemplateRef<any>) {

    if (!this.fileScaricato) {
      this.errors = [];
      this.errors.push('Occorre scaricare il file');
      this.showToast('warning',
        'Occorre scaricare il file',
        'Riprovare dopo aver scaricato il file');
      return;
    }
    if (!this.multisigTerms) {
      this.errors = [];
      this.errors.push('Accettare i Termini e le Condizioni del servizio di Multisig');
      this.showToast('warning',
        'Accettare i Termini e le Condizioni del servizio di Multisig',
        'Riprovare dopo aver accettato i Termini e le Condizioni');
      return;
    }
    /*if (!this.identityCard || this.identityCard.length < 7) {
      this.errors = [];
      this.errors.push('Inserire un identificativo valido');
      this.showToast('warning',
        'Inserire un identificativo valido',
        'Riprovare con una Carta d\'Identità valida');
      return;
    }*/

    this.otpDialog = this.dialogService.open(emptySdiPecDialog, {context: 'Non hai inserito un indirizzo telematico ( SdI / Pec )\n per ricevere la fattura elettronica.', closeOnBackdropClick: false, closeOnEsc: false});
  }

  async sendOtp() {
    
    var hastel=(!isNullOrUndefined(this.user.telNumber) && this.user.telNumber.length>0?true:false);
   
    await this.registrationService.requestOTPCode(this.registration.hash).toPromise().then(res => {
      this.messages = [];
      this.messages.push(
        (hastel?'Codice OTP inviato al numero di telefono indicato':'Codice OTP inviato alla mail collegata a SPID'));
      this.showToast('info',
      (hastel?'Codice OTP inviato al numero di telefono indicato':'Codice OTP inviato alla mail collegata a SPID'),
      (hastel?'Controllare i messaggi SMS':'Controllare i messaggi Email'));
      this.showOtpInput = true;
    })
  }

  async signWithOTP() {
    const encryptedWallet = await this.walletService.getWallet('algorand').toPromise();

    const password = localStorage.getItem('password') || 'defaultPassword';
  
    const algorandWallet = await this.walletService.getWallet('algorand').toPromise();
    const signer = algorandWallet.name;
    const parsedAlgowallet = JSON.parse(algorandWallet.json);
    const encryptedPrivateKey = parsedAlgowallet.secret_key;
    const privateKey = CryptoAES.decrypt(encryptedPrivateKey.toString(), password).toString(crypto.enc.Utf8);
    const address = parsedAlgowallet.address;

    const {hash} = this.registration;
  
    const skArray = Uint8Array.from(privateKey.split(','));
    const signedPayload = await ed.sign(hash, skArray.slice(0, 32));

    const userSig: UserSignature = {
      userId: this.user.userId,
      hash: this.registration.hash,
      id: this.identityCard,
      terms: this.multisigTerms,
      signature: signedPayload,
      signer: encryptedWallet.name,
    };

    const token = (await this.authService.getToken().toPromise());

    if (this.otp.length >= 5) {
      await this.registrationService.verifyOTPCode(this.registration.hash, this.otp).toPromise().then(res => {
        this.verificationService.sign(userSig, token.getValue()).toPromise().then(res1 => {
          // ...
          this.messages = [];
          this.messages.push('La registrazione è stata firmata con successo');
          this.showToast('success',
            'La registrazione è stata firmata con successo', '')
          //'La firma è stata apposta con il borsellino: ' + encryptedWallet.name);
          this.sign();
          this.refreshAfterSign(1500);
          ///INVIA MAIL A OWNER PRATICA  
          
        }).catch(e => {
          this.errors = [];
          this.errors.push('Errore durante la firma');
          this.showToast('warning',
            'Errore durante la firma',
            e.getMessage());
        })
        this.isOtpVerified = true
      })
    } else {
      this.isOtpVerified = false;
      this.showOtpInput = false;
      this.errors = [];
      this.errors.push('Codice OTP non valido');
      this.showToast('warning',
        'Inserire un codice OTP valido',
        '');
      return;
    }
  }

  reload() {
    window.location.reload();
  }

  async requestSignatureWithEmailAndFiscalCode() {

    this.toastrService.show(
      'Abbiamo preso in carica la richiesta, attendi...',
      this.multisig.link,
      // @ts-ignore
      {duration: 5000, position: 'top-center', status: 'info'});
     var signatory: string = '';
    var fiscalCode: string = '';//this.multisig.fiscalCode;
    if(!isNullOrUndefined(this.multisig.invited)) {
      signatory=this.multisig.invited.toUpperCase();
    }else{
      signatory=this.registration.codFiscalePratica;
    }
   // alert(signatory);
    
   
    var  codfiscali= signatory.split(/\s+/);
    const CFRegex = new RegExp('^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$');
   
   // const CFRegex = new RegExp(\/^([A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/);
    codfiscali.forEach(cod=>{
      //alert(cod);
      if (!CFRegex.test(cod)) {
      this.errors = [];
      this.errors.push('Non abbiamo potuto validare il seguente codice fiscale: ' + cod);
      this.showToast('error',
        'Non abbiamo potuto validare il seguente codice fiscale: ' + cod,
        'Riprovare con codice fiscale corretto');
     
      }
    });
   if(this.errors && this.errors.length>0)return;
  
/*    if (signatory!='' && !DeditVerificationComponent.validateEmail(signatory)) {
      this.errors = [];
      this.errors.push('Non abbiamo potuto validare il seguente indirizzo email: ' + signatory);
      this.showToast('error',
        'Non abbiamo potuto validare il seguente indirizzo email: ' + signatory,
        'Riprovare con indirizzi email corretti');
      return;
    }
    */
/*
 
    const CFRegex = new RegExp('^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$');
    if (!fiscalCode || fiscalCode.length < 7) { // !CFRegex.test(fiscalCode)) {
      this.errors = [];
      this.errors.push('Non abbiamo potuto validare il seguente codice fiscale: ' + fiscalCode);
      this.showToast('error',
        'Non abbiamo potuto validare il seguente codice fiscale: ' + fiscalCode,
        'Riprovare con codice fiscale corretto');
      return;
    }
 */
    const token = (await this.authService.getToken().toPromise());
    const user = token.getPayload();
    const multisigMaster = localStorage.getItem('email');

/*
Gentile $Nome $Cognome,
per la visualizzazione, lo scambio e la firma digitale del tuo Mandato irrevocabile Prestito Personale con la banca puoi utilizzare la piattaforma BNL.Dedit.io, l’innovativo processo attivato da BNL BNP Paribas.  
Accedi all’area riservata del servizio direttamente da bnl.it https://bnl.it/it/Individui-e-Famiglie/Firma-digitale 
Entra con la tua identità digitale SPID, troverai la tua documentazione già completa e pronta per la firma 1). Potrai visualizzare, scaricare e salvare i documenti sul tuo pc o smartphone mentre la piattaforma ne invierà una copia a BNL BNP Paribas. 
Ricordati di tenere a portata di mano le tue credenziali SPID!
Per maggiori informazioni sulla firma digitale BNL.Dedit.io puoi chiamare il tuo Consulente o il Centro Relazioni con la Clientela.
Cordiali saluti, 
BNL Gruppo BNP Paribas
*/
      // const to = users.filter(u => u.email === multisigSigner)[0];
      // console.log(to);
      //INVIO PROMEMORIA A ME STESSO
    //  await this.verificationService.sendCertificate(formData, token.getValue()).catch(e => console.log('Error ', e));  
      
      //DISABILITO INVITO CLIENTE
      //await this.verificationService.sendCertificate(formData, token.getValue()).catch(e => console.log('Error ', e));     
      codfiscali.forEach(async cod=>{
      
    await (await this.generatePdf()).getBuffer(async buffer => {
      const pdfBlob = new Blob([buffer], {type: 'application/pdf'});

      const formData = new FormData();
      formData.append('CodiceFiscale', '');
      formData.append('file', pdfBlob, 'Dedit Certificate - ' + this.registration.hash + '.pdf');
      formData.append('to', multisigMaster);
      formData.append('subject', 'REMINDER DA INVIARE A: '+ cod +' Firma a distanza il Mandato irrevocabile Prestito Personale con BNL BNP Paribas');
      formData.append('body', 
      
     
        multisigMaster +
        ' ti ha invitato a firmare e a scaricare il documento notarizzato: "' + this.registration.description +
        '" su Dedit! Per apporre la Firma registrati a DEDIT o accedi al sistema e ' +
        'poi clicca sul sequente link per firmare la registrazione e/o scaricare il documento originale: '
        + encodeURIComponent(this.multisig.link));  
        await this.verificationService.sendCertificate(formData, token.getValue()).catch(e => console.log('Error ', e));     
        await this.verificationService.sendSignRequest(this.registration.hash,  token.getValue(), cod, signatory).catch(e => console.log('Error ', e));
      });
      

      this.refreshAfterSign(5000)
    })
  }

  async isRegistrationAwaitingSignature() {
    this.rightfulSigner = await this.registrationService.isAwaitingSignature(this.registration.hash).toPromise();
  }
}
